import { motion } from "framer-motion";
export default function HeaderButton({ isActive, setIsActive, className }) {
  return (
    <div
      onClick={() => setIsActive(!isActive)}
      className={`h-12 w-12  rounded  cursor-pointer overflow-hidden  ${className}`}
    >
      <motion.div
        className="relative h-full w-full"
        transition={{
          duration: 0.5,
          ease: [0.76, 0, 0.24, 1],
        }}
      >
        <div className="h-full w-full   p-2">
          <MenuIcon isActive={isActive} />
        </div>
      </motion.div>
    </div>
  );
}

function MenuIcon({ isActive }) {
  return (
    <div className="relative h-full w-full ">
      <motion.span
        className={`absolute top-1 w-full h-0.5 bg-gray-700 transition-all ease-linear duration-300  ${
          isActive
            ? "top-1/2 rotate-45 -translate-y-1/2"
            : "top-1 rotate-0 translate-y-0"
        }`}
      ></motion.span>
      <motion.span
        className="absolute top-1/2 -translate-y-1/2 box-content w-full h-0.5 bg-gray-700"
        animate={{
          opacity: isActive ? 0 : 1,
        }}
      ></motion.span>
      <motion.span
        className={`absolute bottom-1 w-full h-0.5 bg-gray-700 transition-all ease-linear duration-300  ${
          isActive
            ? "bottom-1/2 -rotate-45 translate-y-1/2"
            : "bottom-1 rotate-0 translate-y-0"
        }`}
      ></motion.span>
    </div>
  );
}
