"use client";
import HeaderButton from "./Button";
import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import Nav from "./Nav";
import { AnimatePresence } from "framer-motion";
import MagneticWrap from "../MagneticWrap";

const variants = {
  open: {
    width: 280,
    height: 370,
    top: -12,
    right: -12,
    transition: {
      duration: 0.55,
      ease: [0.76, 0, 0.24, 1],
    },
  },
  closed: {
    width: 48,
    height: 48,
    top: 0,
    right: 0,
    transition: {
      duration: 0.55,
      delay: 0.25,
      ease: [0.76, 0, 0.24, 1],
    },
  },
};

export default function Header() {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsActive(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="fixed right-5 top-5 z-20 ">
        <MagneticWrap control={isActive}>
          <motion.div
            variants={variants}
            animate={isActive ? "open" : "closed"}
            initial="closed"
            className="  backdrop-blur-xl bg-white/80 rounded-lg relative shadow-2xl"
          >
            <AnimatePresence>
              {isActive && <Nav setIsActive={setIsActive} />}
            </AnimatePresence>
          </motion.div>
          <HeaderButton
            className={"absolute top-0 right-0 "}
            isActive={isActive}
            setIsActive={setIsActive}
          />
        </MagneticWrap>
      </div>
    </>
  );
}
