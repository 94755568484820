import MagneticWrap from "@/components/MagneticWrap";
import { motion } from "framer-motion";
import Link from "next/link";

const Links = [
  {
    name: "主页 ",
    sec: "Home",
    href: "/",
  },
  {
    name: "雅雅 Bot",
    sec: "Talk",
    href: "/talk",
  },
];

const perspective = {
  initial: {
    opacity: 0,
    rotateX: 90,
    translateY: 80,
    translateX: -20,
  },
  enter: (i) => ({
    opacity: 1,
    rotateX: 0,
    translateY: 0,
    translateX: 0,
    transition: {
      duration: 0.65,
      delay: 0.3 + i * 0.2,
      //   opacity: {
      //     duration: 0.35,
      //   },
      ease: [0.215, 0.61, 0.355, 1],
    },
  }),
  exit: {
    opacity: 0,
    transition: {
      duration: 0.25,
      ease: [0.76, 0, 0.24, 1],
    },
  },
};

export default function Nav({ setIsActive }) {
  return (
    <div className="h-full pt-24 pr-10 pb-12 pl-10 box-border">
      <div className="flex flex-col h-full">
        {Links.map((link, i) => {
          return (
            <div
              key={i}
              className="text-center mb-6"
              style={{
                perspective: "120px",
                perspectiveOrigin: "top",
              }}
            >
              <motion.div
                custom={i}
                variants={perspective}
                animate="enter"
                exit="exit"
                initial="initial"
              >
                <MagneticWrap>
                  <Link href={link.href} onClick={() => setIsActive(false)}>
                    <p className="text-3xl  text-black "> {link.name}</p>
                    <p> {link.sec}</p>
                  </Link>
                </MagneticWrap>
              </motion.div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
