"use client";
import { Providers } from "./providers";
import "./globals.css"; // 如果您有全局样式文件
import Header from "@/components/Header";
import { AnimatePresence } from "framer-motion";

export default function RootLayout({ children }) {
  return (
    <html lang="en">
      <body>
        <Providers>
          <Header />
          {/* <AnimatePresence mode="wait">{children}</AnimatePresence> */}
          {children}
        </Providers>
      </body>
    </html>
  );
}
