import { motion } from "framer-motion";
import { useRef, useEffect, useState } from "react";
export default function MagneticWrap({ children, control }) {
  const wrapRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const mouseMove = (e) => {
    if (control) {
      setPosition({ x: 0, y: 0 });
      return;
    }
    const { clientX, clientY } = e;
    const { width, height, top, left } =
      wrapRef.current.getBoundingClientRect();
    const x = clientX - (left + width / 2);
    const y = clientY - (top + height / 2);
    setPosition({ x, y });
  };

  const mouseLeave = () => {
    setPosition({ x: 0, y: 0 });
  };

  useEffect(() => {
    if (control) {
      setPosition({ x: 0, y: 0 });
      wrapRef.current?.removeEventListener("mousemove", mouseMove);
      wrapRef.current?.removeEventListener("mouseleave", mouseLeave);
    } else {
      // 延迟 0.5 秒后添加事件监听
      const timer = setTimeout(() => {
        wrapRef.current.addEventListener("mousemove", mouseMove);
        wrapRef.current.addEventListener("mouseleave", mouseLeave);
      }, 500);

      // 清理函数
      return () => {
        clearTimeout(timer);
        wrapRef.current?.removeEventListener("mousemove", mouseMove);
        wrapRef.current?.removeEventListener("mouseleave", mouseLeave);
      };
    }
  }, [control]);

  return (
    <motion.div
      className="relative"
      ref={wrapRef}
      animate={{ x: position.x, y: position.y }}
      transition={{
        type: "spring",
        stiffness: 150,
        damping: 15,
        mass: 0.1,
      }}
    >
      {children}
    </motion.div>
  );
}
